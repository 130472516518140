.App {
  display: block;
  width: 100vw;
  height: 100vh;
}

.App *
{
  font-family: BT Curve,Century Gothic,Arial,sans-serif;
  box-sizing: border-box;
}

#PageWrapper
{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1080px;
  width: 1920px;
  display: block;
  overflow: hidden;
  background: #863ECB;
}

.NavPane
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 16px 46px;
  z-index: 10;
}

.NavPane img.Logo
{
  display: block;
  width: 48px;
  height: 48px;
  object-fit: contain;
  object-position: left center;
  curson: pointer;
}

#MainContainer
{
  height: calc(100% - 80px);
  width: 100%;
  position: absolute;
  left: 0;
  top: 80px;

}

#MainContainer .BackgroundContainer
{
  display: block;
  height: 1080px;
  width: 1920px;
  position: absolute;
  top: -80px;
  left: 0;
  pointer-events: none;
}

#MainContainer .BackgroundContainer #mainBackground
{
  position: absolute;
  z-index: 0;
  height: 1080px;
  width: 1920px;
  left: 0;
  top: 0;
}

#MainContainer .BackgroundContainer .SectionVideo
{
  position: absolute;
  z-index: 1;
  height: 1080px;
  width: 1920px;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

#MainContainer .BackgroundContainer .SectionVideo.active
{
  opacity: 1;
  transition: opacity 0.5s 0.5s ease-in-out;
}

.SectionMenu
{
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  padding: 20px 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.SectionMenu .SectionButton
{
  height: 80px;
  width: 310px;
  border: 2px solid transparent;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  transition: border-color 0.5s ease-in-out;
  cursor: pointer;
}

.SectionMenu .SectionButton:not(:last-of-type)
{
  margin-right: 20px;
}

.SectionMenu .SectionButton:hover
{
  border-color: rgba(255,255,255,0.6);
}

.SectionMenu .SectionButton.active, .SectionMenu .SectionButton.active:hover
{
  border-color: rgba(255,255,255,1);
}

.SectionMenu .SectionButton p
{
  font-size: 18px;
  line-height: 22px;
  color: white;
  font-weight: 400;
  text-align: center;
}

.SectionText
{
  position: absolute;
  left: 90px;
  height: 100%;
  pointer-events: none;
  z-index: 9;
}

.SectionText .SectionTextContainer
{
  position: absolute;
  top: 50%;
  left: 0;
  width: 490px;
  pointer-events: all;
  transform: translateY(-50%);
  text-align: left;
}

.SectionText .SectionTextContainer h2
{
  font-size: 33px;
  line-height: 44px;
  margin-bottom: 35px;
  color: white;
  font-weight: 500;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-600px);
}

.SectionText .SectionTextContainer h4
{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 35px;
  color: white;
  font-weight: 400;
  transition: transform 0.5s 0.25s ease-in-out;
  transform: translateX(-600px);
}

.SectionText .SectionTextContainer p
{
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
  color: white;
  font-weight: 400;
  transition: transform 0.5s 0.5s ease-in-out;
  transform: translateX(-600px);
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  color: #5514b4;
}

.SectionText .SectionTextContainer p span
{
  font-weight: 900;
  font-size: 1.2em;
  white-space: nowrap;
}

.SectionText .SectionTextContainer.active h2
{
  transform: translateX(0);
  transition: transform 0.5s 0.7s ease-in-out;
}

.SectionText .SectionTextContainer.active h4
{
  transform: translateX(0);
  transition: transform 0.5s 0.95s ease-in-out;
}

.SectionText .SectionTextContainer.active p
{
  transform: translateX(0);
  transition: transform 0.5s 1.2s ease-in-out;
}

.HotspotButton
{
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 999px;
  background-color: white;
  display: block;
  z-index: 20;
  transform: translate(50%, -50%) scale(0);
  animation: startHotspots 0.5s ease-in-out forwards;
}

@keyframes startHotspots {
  from
  {
    transform: translate(50%, -50%) scale(0);
  }
  to
  {
    transform: translate(50%, -50%) scale(1);
  }
}


.HotspotButton:after
{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 9px;
  height: 9px;
  border-radius: 9999px;
  transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
}

.HotspotButton.active:after
{
  width: 20px;
  height: 20px;
}

.HotspotButton:before
{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 9999px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  background-color: white;
  transition: height 0.5s ease-in-out, width 0.5s ease-in-out, opacity 0.5s ease-in-out;
  animation: pulse 2s ease-in-out infinite;
  animation-delay: 0.5s;
}

.HotspotButton.active:before
{
  width: 20px;
  height: 20px;
  animation: pulseBig 2s ease-in-out infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }

  to {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}

@keyframes pulseBig {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }

  to {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}

.HotspotButton > span
{
  position: absolute;
  height: 1px;
  width: 1px;
  background-color: white;
}

.HotspotButton.Left > span
{
  right: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.HotspotButton.Right > span
{
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.HotspotButton.Top > span
{
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.HotspotButton.Bottom > span
{
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.HotspotButton > p
{
  position: absolute;
  color: white;
  font-size: 18px;
  line-height: 1.1em;
  font-weight: 500;
  text-align: center;
  padding: 14px 16px;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 999px;
  width: 280px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.HotspotButton.Right > p
{
  top: 50%;
  transform: translate(100%,-50%);
}

.HotspotButton.Left > p
{
  top: 50%;
  transform: translate(-100%,-50%);
}

.HotspotButton.Bottom > p
{
  left: 50%;
  transform: translate(-50%, 100%);
}

.HotspotButton.Top > p
{
  left: 50%;
  transform: translate(-50%, -100%);
}

.HotspotButton > p:hover
{
  color: #5514B4;
  background-color: white;
}

.HotspotButton.active > p
{
  color: #5514B4;
  background-color: white;
  border-color: #5514B4;
}

.CallOut
{
  display: block;
  height: 875px;
  width: 600px;
  background-color: white;
  border-top-right-radius: 28px;
  position: absolute;
  z-index: 50;
  bottom: -2px;
  left: 0;
  transform: translateX(-601px);
  transition: transform 0.5s ease-in-out;
  padding: 0;
}


.CallOut.Safari

{
overflow: hidden;
}

.CallOut.active
{
  transform: translateX(-2px);
  transition: transform 0.5s ease-in-out;
  z-index: 51;
}

.calloutDisplayed .CallOut.active
{
  transition: transform 0.5s .7s ease-in-out;
}

.CallOut .ScrollableWrapper
{
  overflow: hidden auto;
  max-height: calc(100% - 30px);
  margin-top: 30px;
}

/* width */
.CallOut .ScrollableWrapper::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.CallOut .ScrollableWrapper::-webkit-scrollbar-track {
  background: #fff !important;
}

/* Handle */
.CallOut .ScrollableWrapper::-webkit-scrollbar-thumb {
  background: #5514B4 !important;
}

.CallOut .Scrollable
{
  width: 100%;
  padding: 0 60px 30px;
}

.CallOut .CloseButton
{
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid #5514B4;
  display: block;
  height: 28px;
  width: 28px;
  border-radius: 9999px;
  z-index: 10;
  cursor: pointer;
  background-color: white;
}

.CallOut .CloseButton:hover
{
  background-color: #5514B4;
}

.CallOut .CloseButton:after, .CallOut .CloseButton:before
{
  display: block;
  content: '';
  height: 1px;
  border-radius: 3px;
  width: 14px;
  background-color: #5514B4;
  position: absolute;
  top: 50%;
  left: 50%;
}

.CallOut .CloseButton:after {transform: translate(-50%, -50%) rotate(-45deg)}
.CallOut .CloseButton:before {transform: translate(-50%, -50%) rotate(45deg)}

.CallOut .CloseButton:hover:after, .CallOut .CloseButton:hover:before
{
  background-color: white;
}

.CallOut .SectionIcon
{
  display: block;
  width: 105px;
  margin: 0 0 15px 0;
}

.CallOut .Scrollable > h2
{
  color: #5514B4;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin: 0 0 20px 0;
}

.CallOut .Scrollable > article
{
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 35px;
}

.CallOut .Scrollable > h3
{
  color: #5514B4;
  margin: 0 0 25px 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
}

.BTSolution
{
  display: flex;
  align-items: flex-start;
}

.BTSolution .Icon
{
  height: 30px;
  min-width: 46px;
  width: 46px;
  object-fit: contain;
  object-position: left top;
  margin: 0;
}

.BTSolution:not(:last-of-type)
{
  margin-bottom: 40px;
}

.BTSolution:last-of-type
{
  margin-bottom: 0;
}

.BTSolution .BTSolutionContent h5
{
  margin: -4px 0 10px 0;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
  font-weight: 500;
}

.BTSolution .BTSolutionContent article
{
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 20px;
}

.BTSolution .BTSolutionContent > button, .CallOut .BTSolutionCallout > button
{
  font-size: 16px;
  line-height: 24px;
  color: #5514B4;
  text-decoration: none;
  font-weight: 400;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background: none;
}

.CallOut .BTSolutionCallout > button
{
  position: absolute;
  top: 69px;
  left: 60px;
  z-index: 2;
}

.BTSolution .BTSolutionContent > button:hover, .CallOut .BTSolutionCallout > button:hover
{
  text-decoration: underline;
}

.BTSolution .BTSolutionContent > button:after, .CallOut .BTSolutionCallout > button:before
{
  content: '';
  background-image: url("./images/findoutmorearrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin-left: 15px;
  height: 8px;
  width: 15px;
  transition: margin-left 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.CallOut .BTSolutionCallout > button:before
{
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 15px;
}

.BTSolution .BTSolutionContent > button:hover:after
{
    margin-left: 30px;
}

.CallOut .BTSolutionCallout > button:hover:before
{
  transform: rotate(180deg) translateX(30px);
}

.BTSolutionCallout
{
  display: block;
  height: 875px;
  width: 600px;
  background-color: white;
  border-top-right-radius: 28px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  transform: translateX(-601px);
  transition: transform 0.5s ease-in-out;
  padding: 0;
  animation: shadow_rev 0.5s ease-in-out;
}

.BTSolutionCallout.active
{
  transform: translateX(0px);
  transition: transform 0.5s ease-in-out;
  z-index: 3;
  animation: shadow 0.5s ease-in-out;
}

.CallOut .BTSolutionCallout .Scrollable
{
  padding-top: 150px;
}

.CoveringPane
{
  position: absolute;
  z-index: 1;
  width: calc(100% - 59px);
  height: 150px;
  top: 0;
  left: 0;
  background: white;
}

@keyframes shadow {
  0% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0)}
  10% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0.2)}
  80% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0.2)}
  100% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0)}
}

@keyframes shadow_rev {
  0% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0)}
  20% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0.2)}
  90% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0.2)}
  100% {box-shadow: 0 20px 20px 10px rgba(0,0,0,0)}
}

article img
{
  position: absolute;
}

article p
{
  font-size: inherit;
  line-height: inherit;
  font-weight: 400;
  color: inherit;
  margin: 0;
}

article p:not(:last-child)
{
  margin: 0 0 15px 0;
}

article p strong
{
  color: #5514B4;
}

article ul
{
  padding-left: 15px;
  margin: 0;
  list-style: none;
}

article ul:not(:last-child)
{
  margin: 0 0 15px 0;
}

ul li::before {
  content: "\2022";
  color: #5514B4;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  transform: translateY(-2px);
}

article li:not(:last-of-type)
{
  margin: 0 0 10px 0;
}

article a, article a:visited, article a:focus
{
  color: #5514B4;
}

article *:last-child
{
  margin: 0;
}

article h2
{
  color: #5514B4;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin: 0 0 25px 0;
}

article h2:not(:first-of-type)
{
  margin-top: 80px;
}

article h2:first-of-type
{
  margin-left: 55px;
  position: relative;
  z-index: 2;
}

article .calloutIcon
{
  position: absolute;
  z-index: 1;
  width: 40px;
  margin-top: 7px;
}

.getInTouchButton
{
  color: black;
  font-size: 16px;
  padding: 14px 16px;
  background: #FF80FF;
  border-radius: 5px;
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  text-decoration: none;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #863ECB;
  z-index: 999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease-in-out;

}
.loading.hide {
opacity: 0;
pointer-events: none;

}
.circle {

  width: 120px; 
  height: 120px; 
  border: 3px solid transparent; 
  border-top: 3px solid white;
  
  animation: loading 1s linear infinite;
  border-radius: 9999px;
  
  }
  
  
  
  @keyframes loading 
  {
  
  from {transform: rotate(0)}
  
  to {transform: rotate(360deg)}
  
  }


